import React from 'react';
import Loading from 'react-loading';
import Buttonbar from '../Common/Buttonbar';
import DialogBox from '../Common/DialogBox';
import APIResponseDialog from '../Common/APIResponseDialog';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { dynamicOEButtonCall } from '../../actions/API';
import { get } from '../../actions/REST';
import classNames from 'classnames';

const buttonTypes = require('../../constants/buttonTypes.js');

const grid = 3;
const lightblue_gradient = 'linear-gradient(to top, #161855 0%, #2989d8 87%, #207cca 94%, #969abf 100%)';
const getItemStyle = (isDragging, draggableStyle, temporary, locked, buttonbar, isThereAClass) => {
  let style = {
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background color if dragging
    //background: temporary ? lightblue_gradient : (isDragging ? lightblue_gradient : 'white'),
    //color: temporary ? 'white' : (isDragging ? 'white' : '#3c3c3c'),
    border: '1px solid #3c3c3c',
    borderRadius: '2px',
    // styles we need to apply on draggables
    ...draggableStyle,
  };
  if (!isThereAClass) {
    if (locked) {
      const locked_style = buttonbar  
        ? {
          background: '#b3b3b3',
          cursor: 'pointer',
        }
      : {
          background: '#b3b3b3',
          cursor: 'not-allowed',
        };
      Object.assign(style, locked_style);
    } else {
      Object.assign(style, {
        background: temporary ? lightblue_gradient : isDragging ? lightblue_gradient : 'white',
        color: temporary ? 'white' : isDragging ? 'white' : '#3c3c3c',
      });
    }
  }
  return style;
};

const getDropZoneStyle = isDraggingOver => ({
  // change background color if dragging over the DropZone
  background: isDraggingOver ? 'lightblue' : '#f0f0f0',
  padding: grid,
  minWidth: 190,
  minHeight: 65,
  marginLeft: 5,
});

export default class DropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog_box: {
        show: false,
        data: null,
        uniqueID: '',
      },
      dialog: {
        open: false,
        response: null,
      },
      loading: false,
    };
  }

  getLockedIcon(locked) {
    if (locked) {
      /*<Glyphicon
          glyph="lock"
          height={20}
          width={20}
          style={{marginRight:'5px',color:'black'}}
        />*/
      return <span>icon</span>;
    } else {
      return null;
    }
  }

  getLoadingIcon(temporary) {
    if (temporary) {
      return (
        <div style={{ float: 'left' }}>
          <Loading color="white" type="spokes" height={20} width={20} style={{ marginRight: '5px' }} />
        </div>
      );
    } else {
      return null;
    }
  }

  /*
   ** param {object} - response
   */
  openAPIDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState(
      {
        loading: false,
        dialog: {
          open: true,
          response: response,
        },
      },
      () => {
        setTimeout(() => {
          this?.setState({ dialog: { open: false, response: response } });
        }, 3000);
      },
    );
  }

  //Close the DialogBox and delete the data from the state
  closeDialogBox() {
    this.setState({
      loading: false,
      dialog_box: {
        show: false,
        data: null,
      },
    });
  }

  /*
   ** Open the DialogBox passing the button clicked as the data
   **
   ** param {Object} - data
   ** param {String} - dialog_type
   */
  openDialogBox(data, dialog_type, uniqueID) {
    this.setState({
      loading: false,
      dialog_box: {
        uniqueID,
        data: data,
        show: true,
        dialog_type,
      },
    });
  }

  getDialogBoxData(button, uniqueID) {
    if (button.endpoint) {
      this.setState({ loading: true });
      get(button.variables, `/${button.endpoint}`, 'Fetching data for DialogBox').then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response.data, button.dialog_type, uniqueID);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
            this.openAPIDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            });
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.setState({ loading: false }, () => this.openAPIDialog(response));
        }
      });
    } else {
      //inline dialogBox
      this.openDialogBox(button, button.dialog_type, uniqueID);
    }
  }

  handleButton(button, uniqueID) {
    if (button.action === buttonTypes.DIALOG_BOX) {
      return this.getDialogBoxData(button, uniqueID);
    } else if (button.endpoint && button.method) {
      const variables = button.variables || {};
      dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
        this.openAPIDialog(response);
        if (response.status >= 200 && response.status < 300) {
          this.props.refreshObjects();
        }
      });
    } else {
      console.warn('please include endpoint and method');
    }
  }

  getButtonbar(buttonbar, uniqueID) {
    if (buttonbar && buttonbar.length > 0) {
      return (
        <div style={{ float: 'left' }}>
          <Buttonbar uniqueID={uniqueID} buttonbar={buttonbar} handleButton={this.handleButton.bind(this)} />
        </div>
      );
    }
  }

  renderDialogBox() {
    const { dialog_box } = this.state;
    const initialPosition = {
      position: 'absolute',
      left: '20%',
      top: '15%',
    };
    return (
      <DialogBox
        key={'dialog_box' + dialog_box.uniqueID}
        data={dialog_box.data}
        initialPosition={initialPosition}
        dialog_type={dialog_box.dialog_type}
        onClose={this.closeDialogBox.bind(this)}
        onSuccess={this.props.refreshObjects}
        openDialog={this.openAPIDialog.bind(this)}
      />
    );
  }

  render() {
    const darker_style = {
      background: 'rgb(90, 89, 89)',
      color: 'black',
    };
    let fontcolor = '';
    if (this.props.buttonbar === undefined) {
      fontcolor = process.env.REACT_APP_THEME == "epr" ? 'white' : 'black';
    }
    const dropzone_wrapper_class = process.env.REACT_APP_THEME === "epr" ? "dropzone-wrapper" : "dropzone-wrapper netduty";
    const dropzone_header_class = process.env.REACT_APP_THEME === "epr" ? "dropzone-header" : "dropzone-header netduty";

    return (
      <div className={dropzone_wrapper_class}>
        {this.state.loading && (
          <div style={{ position: 'absolute' }}>
            <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
          </div>
        )}
        {this.state.dialog_box.show && this.state.dialog_box.data && this.renderDialogBox()}
        <div style={{ color: fontcolor }} className={classNames(dropzone_header_class, this.props.extraClass)}>
          <Buttonbar
            key="left_buttonbar"
            uniqueID="left_buttonbar"
            buttonbar={this.props.lbuttonbar}
            handleButton={this.handleButton.bind(this)}
          />
          {this.props.header}
          <Buttonbar
            key="right_buttonbar"
            style={{ float: 'right' }}
            uniqueID="right_buttonbar"
            buttonbar={this.props.buttonbar}
            handleButton={this.handleButton.bind(this)}
          />
        </div>
        <div className="dropzone-body">
          <Droppable droppableId={this.props.droppableId} isDropDisabled={this.props.isDropDisabled}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getDropZoneStyle(snapshot.isDraggingOver)}>
                {this.props.items.length > 0 &&
                  this.props.items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      isDragDisabled={item.locked || !item.draggable}
                      draggableId={item.label + item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div key={item.id} className="draggable-element">
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={'draggable-item ' + item.class}
                            style={Object.assign(
                              {},
                              getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                item.temporary,
                                item.locked,
                                item.buttonbar,
                                item.class,
                              ),
                              !item.class && item.darker_style ? darker_style : {},
                            )}
                          >
                            {this.getLoadingIcon(item.temporary)}
                            {this.getButtonbar(item.buttonbar, item.label + item.id, item.iconClass)}
                            <div>
                              {this.getLockedIcon(item.locked_icon)}
                              <span className="title">{item.label}</span>
                            </div>
                            {item.details && <pre className="details">{item.details}</pre>}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true} />
        </div>
      </div>
    );
  }
}
