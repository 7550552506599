import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CustomToolTip from '../Common/CustomToolTip';
import Buttonbar from '../Common/Buttonbar';

export default function MuiTextArea({
  id,
  name,
  label,
  placeholder = '',
  rowsMax = 5,
  colsMax = 30,
  maxLength,
  readOnly = false,
  defaultValue,
  autoComplete,
  onChange,
  hovertext,
  buttonbar,
  handleButton,
  iconClass,
}) {
  return (
    <Box sx={{fontSize: '14px'}} >
      <abbr title={hovertext} >
        <FormLabel sx={{ fontWeight: 'bold', color: 'black', display: 'block', position: 'static' }}>{label} 
          {buttonbar && ( <Buttonbar buttonbar={buttonbar} handleButton={handleButton} />)}
        </FormLabel>
        <TextField
          id={id}
          multiline
          rows={rowsMax}
          name={name}
          placeholder={placeholder}
          type="text"
          variant="outlined"
          size="small"
          disabled={readOnly}
          inputProps={{ maxLength: maxLength }}
          sx={{
            width: `${colsMax}ch`,
            maxWidth: '100%',
	    fontSize: '12px',
            '& textarea': {
              fontSize: '12px',
            },
          }}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          onChange={onChange}
        />
      </abbr>
    </Box>
  );
}
