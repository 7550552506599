import React from 'react';
import Datetime from 'react-datetime';
import CustomToolTip from './CustomToolTip';
import Buttonbar from './Buttonbar';
import moment from 'moment';

// a wrapper class for react-datetime's Datetime component which adds max and min dateTimes
export default class WrappedDatetime extends React.Component {
  constructor (props){
    super(props);
    this.state = {
      value: this.props.value,
      timeConstraints: {}, // constraints on the time picker part of the component
    };
  }

  componentDidMount(){
    if(this.props.value){
      this.updateValueLocally(this.props.value);
    }
  }

  // helper function. returns whether two moment objects occur on the same day
  sameDay(date1, date2) {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    return d1 && d2 && d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  //update the value and the timeconstraints accordingly in the local state
  // DEV-1008 
  // NOTE: DO not use minutes.max and minutes.min to limit the range. It works fine 
  // if step = 1 but causes problems for other values of step
  updateValueLocally(newValue){
    // Note that it is possible for minDate and maxDate to be on the same day
    let newTimeConstraints = {hours: {}, minutes: {}};
    if (this.props.minDate && this.props.maxDate) {
      if(this.sameDay(newValue, this.props.minDate)){
        newTimeConstraints.hours.min = this.props.minDate.hour();
        if(newValue.hour() < this.props.minDate.hour()){
          // Correct an out of range hour
          newValue.hour(this.props.minDate.hour());
        }
        if (newValue.hour() === this.props.minDate.hour() &&
          newValue.minutes() < this.props.minDate.minutes()) {
          // Limit minutes.This does unfortunately cause a flicker on decrease.
          newValue.minutes(this.props.minDate.minutes());
        }
      }
      if(this.sameDay(newValue, this.props.maxDate)){
        newTimeConstraints.hours.max = this.props.maxDate.hour();
        if(newValue.hour() > this.props.maxDate.hour()){
          // Correct an out of range hour
          newValue.hour(this.props.maxDate.hour());
        }
        if (newValue.hour() === this.props.maxDate.hour() &&
          newValue.minutes() > this.props.maxDate.minutes()) {
          // Limit minutes.This does unfortunately cause a flicker on increase.
          newValue.minutes(this.props.maxDate.minutes());
        }

      }
    }
    //In case there's any modification to the default minutes/hours step
    if(this.props.minuteStep){
      newTimeConstraints["minutes"] = Object.assign({}, newTimeConstraints["minutes"], {step: this.props.minuteStep});
    }
    if(this.props.hourStep){
      newTimeConstraints["hours"] = Object.assign({}, newTimeConstraints["hours"], {step: this.props.hourStep});
    }

    this.setState({
      value: newValue,
      timeConstraints: newTimeConstraints,
    });
  }


  isValidDate(date){
    return ((!this.props.minDate || this.sameDay(date, this.props.minDate) || date.isAfter(this.props.minDate))
            && (!this.props.maxDate || this.sameDay(date, this.props.maxDate) || date.isBefore(this.props.maxDate)));
  }

  render(){
    const format12 = "hh:mm A";
    const format24 = "HH:mm";
    let timeFormat = format24;//Default time format is 24 Hours
    if(this.props.format == 12){
      timeFormat = format12;
    } else if (this.props.format === 'no_time') {
        timeFormat = "";
    }
    return(
      <div>
        {this.props.label && (
            <abbr key={'abbr' + new Date().getTime() } title={this.props.hovertext}>
            <label key={'label' + new Date().getTime() }style={{marginRight:'5px'}}className="inputLabel" htmlFor={this.props.name}>
            {this.props.label}
            </label>
            {this.props.icon && <CustomToolTip icon={this.props.icon} iconClass={this.props.iconClass} /> }
            </abbr>
        )}
        {this.props.buttonbar && (
            <Buttonbar
            buttonbar={this.props.buttonbar}
            loading={this.state.loading}
            handleButton={this.props.handleButton}
            />
        )}
        <Datetime
        name={this.props.name}
        value={this.state.value}
        timeFormat={timeFormat}
        inputProps={{readOnly: true}}
        isValidDate={this.isValidDate.bind(this)}
        onChange={this.updateValueLocally.bind(this)}
        onClose={() => {if (this.state.value) this.props.onChange(this.state.value.format('YYYY-MM-DD HH:mm:ss'))} }
        timeConstraints={this.state.timeConstraints}
        />
        </div>
    );
  }
}

