import React from 'react';
import Loading from 'react-loading';
import { dynamicOEButtonCall } from '../../actions/API';
import { get } from '../../actions/REST';
import { DragDropContext } from 'react-beautiful-dnd';
import CustomToolTip from '../Common/CustomToolTip';
import DialogBox from '../Common/DialogBox';
import DropZone from './DropZone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import APIResponseDialog from '../Common/APIResponseDialog';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.container.config.collapsed || false,
    };
  }

  toggleCollapse() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  }

  getRightHeader() {
    const icon = this.state.collapsed ? 'angle-down' : 'angle-up';
    return (
      <div style={{ float: 'right', cursor: 'pointer' }} onClick={this.toggleCollapse.bind(this)}>
        <FontAwesomeIcon icon={icon} size="lg" color="white" />
      </div>
    );
  }

  render() {
    const { container } = this.props;
    const { width, ...wrapperStyleWithOutWidth } = this.props.wrapperStyle;
    const visibility = this.state.collapsed ? { display: 'none' } : {};
    const bg_container_header_class = process.env.REACT_APP_THEME === "epr" ? "bg-container-header" : "bg-container-header-netduty";
    const bg_container_body_class = process.env.REACT_APP_THEME === "epr" ? "bg-container-body" : "bg-container-body-netduty";

    let bgRenderBodyWithBg = '';
    if (container?.config?.label === 'Time Off' && process.env.REACT_APP_THEME === "epr") {
      bgRenderBodyWithBg = '#767880';
    }

    return (
      <div
        className={container.config.label === 'Time Off' ? 'container-wrapper  ' : 'container-wrapper card-width ' + container.config.class}
        style={container.config.label === 'Time Off' ? this.props.wrapperStyle : wrapperStyleWithOutWidth}
      >
        <div
          className={
            container.config.label === 'Time Off'
              ? 'container-header ' + container.config.class
              : `container-header ${bg_container_header_class} ` + container.config.class
          }
        >
          {container.config.label}
          {this.getRightHeader()}
        </div>
        <div
          className={`container-body ${bg_container_body_class} ${container.config.class}`} 
          style={
            this.props.bodyStyle
              ? Object.assign({ background: bgRenderBodyWithBg }, this.props.bodyStyle, visibility)
              : visibility
          }
        >
          {this.props.getZonesWrapper(container.zones)}
        </div>
      </div>
    );
  }
}

export default class OperationsBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog_box: {
        show: false,
        data: null,
        uniqueID: '',
      },
      api_info: {
        endpoint: '',
        endpoint_type: '',
        method: '',
        variables: [],
      },
      screenHeader: {
        label: '',
        class: '',
        alert: false,
        alert_msg: '',
        navigation: null,
      },
      cols: [],
      zones: [],
      dialog: {
        open: false,
        response: null,
      },
      formData: null,
      control_panel: null,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.props.data.data.ribbon &&
      this.props.updateRibbon &&
      this.props.updateRibbon(this.props.data.data.ribbon, true);
    this.props.data && this.props.data.data && this.props.data.data.config && this.parseData(this.props.data.data);
  }

  componentDidUpdate(prevProps) {
    prevProps !== this.props &&
      this.props.data &&
      this.props.data.data &&
      this.props.data.data.config &&
      this.parseData(this.props.data.data);
  }

  getZone(zone) {
    if (!zone.data) {
      console.warn('data property is missing');
    } else if (!Array.isArray(zone.data)) {
      console.warn('data should be an array, if you want an empty zone add data:[]');
    }
    const data = zone.data || [];
    return {
      id: zone.config.id,
      label: zone.config.label,
      api_info: {
        endpoint: zone.config.endpoint,
        endpoint_type: zone.config.endpoint_type,
        method: zone.config.method,
        variables: zone.config.variables,
        onDrop: {
          action: zone.config.ondrop,
          button: zone.config,
        },
      },
      data,
    };
  }

  initControlPanel(config) {
    if ( !config.data || config.data.control_panel ) return '';
    let control_panel = {};
    if (config.data.control_panel.buttons) {
      for (let button of config.data.control_panel.buttons) {
        control_panel = Object.assign({}, control_panel, {
          [button.id]: button.disabled,
        });
      }
    }
    return control_panel
  }


  getButtonAction(button) {

    return function () {
      if ( !button ) return; // NOTE Early return

      let variables = Object.assign({}, button.variables || {});
      variables.confirmed = button.confirm;

      // if the button requires confirmation, submit with confirm=1 and then open a dialog w the response
      // otherwise, go ahead and submit the actual button call
      let successFunc = function (response) {
        if (response.data && !response.data.confirm) {
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
          if (this.props.refreshReports && button.reload_report) {
            this.props.refreshReports && this.props.refreshReports(button.reload_report);
          }
          if (button.close === false) {
            this.props.setRefreshOnClose && this.props.setRefreshOnClose();
          } else {
            this.props.success && this.props.success(response, !button.no_refresh, button.refresh_page);
          }
        }

        /* For cases like DialogBox where we have a Form, when the Form is sent the DialogBox will
         ** close on a success response, unmounting the Form, for that reason we need to be able to
         ** open the APIDialogResponse outside of the Form component so it doesn't throw an error of
         ** setting the state on an unmount component (Form).
         */
        if (this.props.openDialog) {
          this.props.openDialog(response, this.getButtonAction.bind(this), button);
        } else {
          this.openDialog(response, this.getButtonAction.bind(this), button);
        }

        this.setState({
          loading: false,
        });
      };

      if (this.props.variables) {
        variables = Object.assign({}, variables, this.props.variables);
      }

      if (button.action === 'dialog') {
        return this.getDialogBoxData(button, 'dialog',variables);
      }

      this.setState({
        loading: true,
      });

    }.bind(this);
  }

  callAPI(endpoint, endpoint_type, method, variables = {}, dynamicId) {
    let params = Object.assign({}, variables);
    //Adding extra parameter, usually the Changing Dropdown
    if (method === 'GET') {
      params = {
        ...params,
        ...this.state.toggleBtns,
      };
      get(params, `/${endpoint}`, 'Requesting new Form').then(response => {
        if (response.status === 200 && response.data && response.data.form) {
          this.init(response.data.form, dynamicId); //Initialize the Form Again
        } else if (response.status === 200 && response.data && response.data.data) {
          this.init(response.data.data, dynamicId); //Initialize the Form Again
        } else {
          this.openDialog(response);
        }
      }).catch(e => console.log('Failed with error:', e));
    } else {
      console.warn('wrong method');
    }
  }


  getControlPanel() {
    let buttons = [];
    if ( !this.state.formData ) return null;

    let rawButtons = this.state.formData.buttons;
    for (let i = 0; i < rawButtons.length; i++) {
      let button = rawButtons[i];
      /* Based on the control_panel coming from the state we then set each
       ** button to either disabled or not. And we use a Class to change
       ** the disabled buttons appearance. */
      let extraClass = '';
      let disabled = false;
      const { control_panel, loading } = this.state;
      if (!button.always_enabled && ((button.id && control_panel && control_panel[button.id]) || loading)) {
        disabled = control_panel[button.id] || loading;
        extraClass = ' button-disabled';
      }
      let icon = null;
      if (button.icon) {
        icon = <CustomToolTip icon={button.icon} iconClass={button.iconClass} />;
      }
      buttons.push(
        <abbr title={button.hovertext} key={'button' + button.label + button.id}>
          <button
            className={classNames('formButton nd-button', button.class, extraClass)}
            disabled={ disabled === 'false' ? false : Boolean(disabled) }
            type="button"
            onClick={this.getButtonAction(button)}
            value={button.label}
          >   
          {icon}
          {button.label}
          </button>
        </abbr>,
      );
    }
    return (
      <div className="operations-board">
        <div className="buttonContainer">
        {buttons}
        </div>
      </div>
    );
  }

  parseData({ config }) {
    const screenHeader = {
      label: config.label,
      alert: config.alert ?? false,
      alert_msg: config.alert_msg ?? '',
      class: config.class,
      navigation: config.navigation,
      seldate: config.seldate,
    };

    const control_panel = this.initControlPanel(config);
    let zones = [];
    if (config && Array.isArray(config.cols)) {
      for (let col of config.cols) {
        if (col.containers && col.containers.length > 0) {
          for (let container of col.containers) {
            for (let zone of container.zones) {
              if (!zone.config) {
                //If there's no config we "skip" the zone entirely
                console.warn('zone is missing config');
                continue;
              }
              zones.push(this.getZone(zone));
            }
          }
        } else if (col.rows && col.rows.length > 0) {
          for (let row of col.rows) {
            for (let zone of row.zones) {
              if (!zone.config) {
                //If there's no config we "skip" the zone entirely
                console.warn('zone is missing config');
                continue;
              }
              zones.push(this.getZone(zone));
            }
          }
        } else {
          continue;
        }
      }
    } else {
      console.warn('Might be missing cols property');
    }

    this.setState({
      zones,
      screenHeader,
      formData: config.data && config.data.control_panel ? config.data.control_panel : null,
      control_panel,
      cols: config.cols || [],
    });
  }

  openDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState(
      {
        dialog: {
          open: true,
          response: response,
        },
      },
      () => {
        setTimeout(() => {
          this?.setState({ dialog: { open: false, response: response } });
        }, 3000);
      },
    );
  }

  //Close the DialogBox and delete the data from the state
  closeDialogBox() {
    this.reverseTemoraryState(() =>
      this.setState({
        loading: false,
        dialog_box: {
          show: false,
          data: null,
          variables: null,
        },
      }),
    );
  }

  /*
   ** Open the DialogBox passing the button clicked as the data
   **
   ** param {Object} - data
   ** param {String} - dialog_type
   */
  openDialogBox(data, dialog_type, uniqueID, variables) {
    this.setState({
      loading: false,
      dialog_box: {
        uniqueID,
        data: data,
        show: true,
        dialog_type,
        variables,
      },
    });
  }

  getDialogBoxData(button, uniqueID, variables) {
    if (button.endpoint) {
      this.setState({ loading: true });
      get(variables, `/${button.endpoint}`, 'Fetching data for DialogBox').then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response.data, button.dialog_type, uniqueID, variables);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
          this.reverseTemoraryState(() => 
            this.openDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            }));
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.reverseTemoraryState(() => this.openDialog(response));
        }
      });
    } else {
      //inline dialogBox
      this.openDialogBox(button, button.dialog_type, uniqueID, variables);
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    //withing the same Drop-Zone
    if (result.source.droppableId === result.destination.droppableId) {
      return this.setState(prevState => {
        //find the origin
        let zone_to_change = prevState.zones.filter(zone => 'zone_' + zone.id === result.source.droppableId)[0];
        //re-order the list
        const ordered_data = reorder(zone_to_change.data, result.source.index, result.destination.index);
        //change the list to the ordered_data
        zone_to_change.data = ordered_data;
        return {
          zones: prevState.zones.filter(zone => 'zone_' + zone.id !== result.source.droppableId).concat(zone_to_change),
        };
      });
    }
    //When moving to another Drop-Zone
    if (result.source.droppableId !== result.destination.droppableId) {
      let endpoint, method, onDrop;
      let variables = {};
      let temp_element = null;
      for (let zone of this.state.zones) {
        if ('zone_' + zone.id === result.source.droppableId) {
          for (let item of zone.data) {
            //We go through the Items in the Zone where we Originated the drag
            if (item.label + item.id === result.draggableId) {
              temp_element = item;
              temp_element['temporary'] = true;
              if (item.variables) {
                variables = Object.assign({}, variables, item.variables); //Save the ID of the element being dragged to send as params
              }
            }
          }
        }
        if ('zone_' + zone.id === result.destination.droppableId) {
          endpoint = zone.api_info.endpoint;
          method = zone.api_info.method;
          onDrop = zone.api_info.onDrop;
          if (zone.api_info.variables) {
            variables = Object.assign({}, variables, zone.api_info.variables);
          }
        }
      }
      this.setState(prevState => {
        let zone_to_change = prevState.zones.filter(zone => 'zone_' + zone.id === result.destination.droppableId)[0];
        //Modifying the ID to avoid Unregistering issues with React-Beautiful-DND
        zone_to_change.data.push(Object.assign({}, temp_element, { id: 'temporary' }));
        return {
          zones: prevState.zones
            .filter(zone => 'zone_' + zone.id !== result.destination.droppableId)
            .concat(zone_to_change),
        };
      });
      if (onDrop.action === 'dialog') {

        this.getDialogBoxData(onDrop.button, onDrop.action, variables);
      } else {
        dynamicOEButtonCall(`/${endpoint}`, method, variables).then(response => {
          if (!response) {
            this.reverseTemoraryState(() =>
              this.openDialog({
                data: {
                  errmsg: 'Unable to perform this action.',
                },
              }),
            );
          }
          else if (response.status >= 200 && response.status < 300) {
            this.openDialog(response);
            this.props.refreshObjects(this.state.screenHeader.seldate);
          } else {
            //Reverse the temporary state
            this.reverseTemoraryState(() => this.openDialog(response));
          }
        });
      }
    }
  }

  reverseTemoraryState(APIDialogCallback) {
    this.setState(
      prevState => ({
        zones: prevState.zones.map(zone =>
          Object.assign({}, zone, {
            data: zone.data
              .filter(item => item.id !== 'temporary')
              .map(item =>
                Object.assign({}, item, {
                  temporary: false,
                }),
              ),
          }),
        ),
      }),
      APIDialogCallback,
    );
  }

  getZonesWrapper(zones) {
    let rendered_zones = [];
    for (let zone of zones) {
      let items = this.state.zones.filter(state_zone => state_zone.id === zone.config.id)[0];
      rendered_zones.push(
        <DropZone
          key={zone.config.id}
          header={zone.config.label}
          extraClass={zone.config.class}
          buttonbar={zone.config.buttonbar}
          lbuttonbar={zone.config.lbuttonbar}
          isDropDisabled={zone.config.drop_disabled}
          droppableId={'zone_' + zone.config.id}
          refreshObjects={() => this.props.refreshObjects(this.state.screenHeader.seldate)}
          items={items.data}
        />,
      );
    }
    return rendered_zones;
  }

  getContainers(col) {
    const displayFlex = {
      display: 'flex',
      flexWrap: 'wrap',
    };
    let displayInline = {
      display: 'inline-block',
      verticalAlign: 'top',
      width: 'auto',
      marginRight: '10px',
    };
    if (col.rows && Array.isArray(col.rows)) {
      let alternateLayout = false;
      return col.rows.map((container, index) => {
        if (index === 0 && container.config.id === 'ptocat') {
          //Alternate Layouts
          alternateLayout = true;
        }
        if (index === 1 && alternateLayout) {
          //after pto
          displayInline = {
            ...displayInline,
            clear: 'both',
            float: 'left',
          };
        } else {
          displayInline = {
            display: 'inline-block',
            verticalAlign: 'top',
            width: 'auto',
            marginRight: '10px',
          };
        }
        return (
          <>
            {' '}
            <Container
              key={container.config ? container.config.id : 'container_' + index}
              wrapperStyle={container.config.id === 'ptocat' ? { maxWidth: '100%', width: '100%' } : displayInline}
              bodyStyle={container.config.id === 'ptocat' ? displayFlex : {}}
              container={container}
              getZonesWrapper={this.getZonesWrapper.bind(this)}
            />
          </>
        );
      });
    }
    return col.containers.map((container, index) => (
      <Container
        key={container.config ? container.config.id : 'container_' + index}
        container={container}
        getZonesWrapper={this.getZonesWrapper.bind(this)}
      />
    ));
  }

  getColumns(cols) {
    return cols.map(col => (
      <div key={col.id} className="column" style={col.id === 'horizontal_panel' ? { width: '100%' } : {}}>
        {this.getContainers(col)}
      </div>
    ));
  }

  renderDialogBox() {
    const { dialog_box } = this.state;
    return (
      <DialogBox
        key={'dialog_box' + dialog_box.uniqueID}
        data={dialog_box.data}
        variables={dialog_box.variables}
        dialog_type={dialog_box.dialog_type}
        onClose={this.closeDialogBox.bind(this)}
        onSuccess={() => this.props.refreshObjects(this.state.screenHeader.seldate)}
      />
    );
  }

  navigate({ endpoint, method, variables }) {
    dynamicOEButtonCall(`/${endpoint}`, method, variables).then(({ data }) => {
      if (data.ribbon) {
        const param = {
          menu: data.ribbon.menu,
          config: data.ribbon.config,
          variables: {
            seldate: data.config.seldate,
          },
        };
        this.props.updateRibbon(param, true);
      }
      this.parseData(data);
    });
  }

  renderNav() {
    const { navigation, label, class: extraClass, alert, alert_msg } = this.state.screenHeader;

    const lastParams = {
      endpoint: navigation.last.endpoint,
      method: 'GET',
      variables: {
        seldate: navigation.last.seldate,
      },
    };

    const nextParams = {
      endpoint: navigation.next.endpoint,
      method: 'GET',
      variables: {
        seldate: navigation.next.seldate,
      },
    };

    return (
      <div className={classNames('operations-board-header', extraClass)}>
        <div className="navigation">
          <span className="navOpt" onClick={this.navigate.bind(this, lastParams)}>
            {'<< ' + navigation.last.label}
          </span>
          <span className={classNames("dayLabel",extraClass)}>
            <span dangerouslySetInnerHTML={{__html: label}}></span>
            {alert && (
              <div className="alert-wrapper">
                <CustomToolTip icon="alert" text={alert_msg} className="alert-icon" />
              </div>
            )}
          </span>
          <span className="navOpt" onClick={this.navigate.bind(this, nextParams)}>
            {navigation.next.label + ' >>'}
          </span>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.cols && this.state.cols.length > 0) {
      return (
        <>
          { this.state.formData && this.getControlPanel() }
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="operations-board">
	      <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true}/>
              {this.state.dialog_box.show && this.state.dialog_box.data && this.renderDialogBox()}
              {this.state.screenHeader.navigation && this.renderNav()}
              <div className="operations-board-body">{this.getColumns(this.state.cols)}</div>
              {this.state.loading && (
                <div style={{ position: 'absolute' }}>
                  <Loading type="spinningBubbles" color="#207cca" height="100px" width="100px" />
                </div>
              )}
            </div>
          </DragDropContext>
        </>
      );
    } else {
      return null;
    }
  }
}
