import React from 'react';
import Form from './Form';
import ObjectTree from './ObjectTree';
import Results from '../Reports/Results';
import Chart from '../Reports/Charts';
import { getContentFromEndpoint, dynamicOEButtonCall } from '../../actions/API';
import { get } from '../../actions/REST';
import Buttonbar from './Buttonbar';
import APIResponseDialog from './APIResponseDialog';
import DialogBox from './DialogBox';
import classNames from 'classnames';


const buttonTypes = require('../../constants/buttonTypes');

export default class Reports2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      labels: {},
      selected: [], //the currently selected report, stored as an array of size 1 for compatibility with ObjectTree
      showTree: true, //whether or not the report tree is currently expanded
      showForm: true, //whether or not the report editor is currently expanded
      showHeader: false,
      formData: null,
      results: null,
      dialog_box: {
        show: false,
        data: null,
        uniqueID: '',
      },
      dialog: {
        open: false,
        response: null,
      },
      editingID: null, //the id of the report currently selected for editing
    };
  }

  parseData(data) {
    // copy over the relevant stuff so that if
    // format changes this is the only place we have to change it
    let newData = {
      tree: data.tree,
      tree_buttonbar: data.tree_buttonbar,
      custom_report: data.custom_report,
      labels: {
        form: data.labels ? data.labels.form : null,
        tree: data.labels ? data.labels.tree : null,
        results: data.labels ? data.labels.results : null,
      },
    };

    this.setState({
      data: newData,
    });
    if (data.open_report) {
      getContentFromEndpoint(data.open_report.variables, data.open_report.endpoint).then(response => {
        let editingID = null;
        //Sometimes we use org_report_id instead of report_id as a variable an we need to update editingID with that value
        //so the Edit Form refreshes.
        if (data.open_report.variables && (data.open_report.variables.report_id || data.open_report.variables.org_report_id)) {
          editingID = data.open_report.variables.report_id || data.open_report.variables.org_report_id;
        }
        this.setState({
          formData: response.data.form,
          showForm: true,
          editingID,
        });
      });

    }
  }

  componentDidMount() {
    this.parseData(this.props.data.data);
  }

  //set the selected report. Callback to be passed to Object Tree
  setSelected(selected) {
    if (selected.length > 0) {
      this.setState({
        selected: selected,
        showForm: false,
        formData: null,
        editingID: null,
        results: null,
      });
    }
  }

  expandTree() {
    this.setState({
      showTree: !this.state.showTree,
    });
  }
  expandEditor() {
    this.setState({
      showForm: !this.state.showForm,
    });
  }

  handleButton(button) {
    getContentFromEndpoint(button.variables, button.endpoint).then(response => {
      let editingID = null;
      if (response.status === 200) {
        //Sometimes we use org_report_id instead of report_id as a variable an we need to update editingID with that value
        //so the Edit Form refreshes.
        if (button && button.variables && (button.variables.report_id || button.variables.org_report_id)) {
          editingID = button.variables.report_id || button.variables.org_report_id;
        }
        this.setState({
          formData: response.data.form,
          showForm: true,
          editingID,
        });
      } else {
        console.error(`Reports2::getContentFromEndpoint: Error response from API ${response.code}`);
      }
    });
  }

  //if the tree is expanded create an ObjectTree of stored reports,
  //otherwise just display the label of the section
  getTree() {
    return this.state.showTree && this.state.data ? (
      <ObjectTree
        tree={this.state.data.tree}
        setSelectedCallback={this.setSelected.bind(this)}
        simple={true}
        selectedNodes={this.state.selected}
        refreshObjects={this.props.refreshData}
        handleButton={this.handleButton.bind(this)}
      />
    ) : (
      <div className="collapsedLabel">Saved Reports</div>
    );
  }

  // get the card header for the content card if the tree area is expanded
  getTreeHeader() {
    return this.state.showTree ? (
      <div className="cardHeader">
        {this.state.labels.tree || 'Saved Reports'}
        <span className={'glyphicon cardExpandButton glyphicon-resize-small'} onClick={this.expandTree.bind(this)} />
      </div>
    ) : (
      <span
        className={'glyphicon cardExpandButton collapsed glyphicon-resize-full'}
        onClick={this.expandTree.bind(this)}
      />
    );
  }

  setResults(data) {
    if (data.data) {
      let html = data.data; //Assign the entire Object as html in case we get a PHP ERROR from the Back-End
      if (Object.keys(data.data).includes('fullmsg')) {
        //If the fullmsg Key exists pass that as HTML. This will allow the back-end to send {fullmsg: ""}
        html = data.data.fullmsg;
      }
      if (Object.keys(data.data).includes('errmsg')) {
        //If the errmsg Key exists pass that as HTML. This will allow the back-end to send {errmsg: ""}
        html = data.data.errmsg;
      }
      let resultMessage = <div className="resultMessage" dangerouslySetInnerHTML={{ __html: html }} />;
      this.setState({
        results: resultMessage,
        /* Collapse form once we get the results unless
         ** the back-end wants it opened {showForm: true} */
        showForm: data.data.showForm || false,
      });
    }
  }

  //display the custom report form if the section is expanded
  getCustomReportForm() {
    if (this.state.showForm && this.state.formData) {
      return (
        <Form
          formData={this.state.formData}
          refreshReports={this.props.refreshData}
          success={this.setResults.bind(this)}
          key={'reportForm' + this.state.editingID}
        />
      );
    } else {
      return null;
    }
  }

  getResults() {
    if (this.state.selected.length  && this.state.selected[0].chart) {
      return (
        <Chart key={'reportResults' + this.state.selected[0].id} report={this.state.selected[0]} />
      );
    } else if (this.state.results) {
      return this.state.results;
    } else {
      return this.state.showForm || this.state.selected.length <= 0 ? null : (
        <Results key={'reportResults' + this.state.selected[0].id} report={this.state.selected[0]} />
      );
    }
  }

  /*
   ** param {object} - response
   */
  openAPIDialog(response) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState(
      {
        loading: false,
        dialog: {
          open: true,
          response: response,
        },
      },
      () => {
        setTimeout(() => {
          this?.setState({ dialog: { open: false, response: response } });
        }, 3000);
      },
    );
  }

  //Close the DialogBox and delete the data from the state
  closeDialogBox() {
    this.setState(
      {
        dialog_box: {
          show: false,
          data: null,
        },
      },
      () => this.props.idleTimer && this.props.idleTimer.resume(),
    );
  }

  /*
   ** Open the DialogBox passing the button clicked as the data
   **
   ** param {Object} - data
   ** param {String} - dialog_type
   */
  openDialogBox(data, dialog_type, uniqueID) {
    this.setState(
      {
        loading: false,
        dialog_box: {
          uniqueID,
          data: data,
          show: true,
          dialog_type,
        },
      },
      () => this.props.idleTimer && this.props.idleTimer.pause(),
    );
  }

  getDialogBoxData(button, uniqueID) {
    this.setState({ loading: true });
    //If we have an endpoint we load the DialogBox from that endpoint
    if (button.endpoint) {
      get(button.variables, `/${button.endpoint}`, 'Fetching data for DialogBox').then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (
            response &&
            response.data &&
            (response.data.config || (response.data.data && response.data.data.config))
          ) {
            this.openDialogBox(response.data, button.dialog_type, uniqueID);
          } else {
            /* If it's a successful response (200 - 299) but the format is not correct, must likely is a
             ** back-end error. So we clear the LOADING state and we call the APIDialog.*/
            this.openAPIDialog({
              //faking the correct response when there's an Error in the API
              data: {
                errmsg: response.data,
              },
            });
          }
        } else {
          //If it wasn't a successful response then let's open the APIDialog
          this.setState({ loading: false }, () => this.openAPIDialog(response));
        }
      });
    } else {
      //If no endpoint, then it means the DialogBox is inline
      this.openDialogBox(button, button.dialog_type, uniqueID);
    }
  }

  handleButtonbar(button, uniqueID) {
    if (button.action === buttonTypes.DIALOG_BOX) {
      return this.getDialogBoxData(button, uniqueID);
    } else if (button.endpoint && button.method) {
      let variables = {};
      if (button.variables) {
        variables = button.variables;
      }
      dynamicOEButtonCall(button.endpoint, button.method, variables).then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.props.refreshData();
        }
        this.openAPIDialog(response);
      });
    } else {
      console.warn('please include endpoint and method');
    }
  }

  renderDialogBox() {
    const { dialog_box } = this.state;
    return (
      <DialogBox
        key={'dialog_box' + dialog_box.uniqueID}
        data={dialog_box.data}
        dialog_type={dialog_box.dialog_type}
        onClose={this.closeDialogBox.bind(this)}
        onSuccess={this.props.refreshData}
        openDialog={this.openAPIDialog.bind(this)}
      />
    );
  }

  render() {
    //wait until reports has loaded
    if (this.state.data) {
      var results = this.getResults();
      var leftClosed = this.state.showTree ? '' : ' leftClosed';
      var editorClosed = this.state.showForm ? '' : ' designerClosed';

      var editorExpandClass = this.state.showForm ? 'glyphicon-resize-small' : 'glyphicon-resize-full';
      var resultsExpandClass = !this.state.showForm ? 'glyphicon-resize-small' : 'glyphicon-resize-full';

      const showHeaderClass = this.state.showHeader ? '' : ' hideHeader';
      return (
        <div className={classNames('reports', leftClosed, editorClosed, showHeaderClass)}>
          <div className="left">
            <div className="treeView reportsSection contentCard">
              {this.getTreeHeader()}
              <div className="cardBody">
                {this.state.data.tree_buttonbar && (
                  <div className={`top-buttonbar ${this.state.showTree ? '' : 'collapsed'}`}>
                    <Buttonbar
                      style={{ float: 'right' }}
                      buttonbar={this.state.data.tree_buttonbar}
                      handleButton={this.handleButtonbar.bind(this)}
                    />
                  </div>
                )}
                {this.getTree()}
              </div>
            </div>
          </div>
          <div className="right">
            {this.state.dialog_box.show && this.state.dialog_box.data && this.renderDialogBox()}
            <div className={'designer reportsSection contentCard'}>
              <div className="cardHeader">
                {' '}
                {this.state.labels.form || 'Edit Report'}
                <span
                  className={'glyphicon cardExpandButton ' + editorExpandClass}
                  onClick={this.expandEditor.bind(this)}
                />
              </div>
              <div className="cardBody">{this.getCustomReportForm()}</div>
            </div>
            <APIResponseDialog open={this.state.dialog.open} response={this.state.dialog.response} notify={true} />
            {this.state.showHeader && <div className={'header reportsSection contentCard'}>Header</div>}
            <div className={'resultsContainer reportsSection contentCard'}>
              <div className="cardHeader">
                {this.state.labels.results || 'Results'}
                <span
                  className={'glyphicon cardExpandButton ' + resultsExpandClass}
                  onClick={this.expandEditor.bind(this)}
                />
              </div>
              {results}
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
