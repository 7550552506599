import React from 'react';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';

/* a custom options component for WrappedSelect Component */
class CustomOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        ref={this.props.innerRef}
        key={this.props.data.value}
        className={'optLabel ' + this.props.selectProps.className}
        {...this.props.innerProps}
      >
        <div className="labelText">{this.props.data.label}</div>
        <div className="detailText">{this.props.data.details}</div>
      </div>
    );
  }
}

// a wrapper class for react-select's Select component which doesnt keep its own selected state
export default class WrappedSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValueFromAPI(this.props.value),
      myActiveElementType: props.activeElementType,
    };
  }

  // Operation changed from V5.0 (react-select 1.0.0.5rc) 
  // Now it is necessary to explicitly trigger blur (lose focus) 
  // when a tab key is pressed.
  handleKeyPress(e){
      if(e.key === 'Tab'){
          e.target.blur();
      }
  }

  formatValueForAPI(value) {
    if (this.props.multi) {
      let newValues = {};
      for (let i = 0; i < value.length; i++) {
        let selection = value[i];
        newValues[selection.value] = true;
      }
      return newValues;
    } else {
      return value ? value.value : null;
    }
  }

  formatValueFromAPI(value = []) {
    if (this.props.multi) {
      if (value === null) return [];
      // Select requires values as objects in the [{label: label, value: value},...]
      // This should be possible with a map and a filter, but maybe the lookup is
      // easier to read.
      let optToLabel = {}; // lookup table
      this.props.options.map(opt => {
        if (typeof optToLabel[opt.value] === 'undefined') {
          optToLabel[opt.value] = opt.label;
        }
        return;
      });

      let newValues = Object.keys(value).
          filter(item => {return typeof optToLabel[item] !== 'undefined';}).
          map(item => {
            /* The keys of the Objects, are all strings: Convert to 
             * int where the key is actually a number
             */
            const i = isNaN(parseInt(item)) ? item : parseInt(item);
            return { label: optToLabel[item], value: i };
          });

      return newValues;
    } else {
      if (value === null) return '';
      return this.props.options.filter(opt => opt.value === value);
    }
  }

  updateValue(newValue) {
    this.props.onChange(this.formatValueForAPI(newValue));
    this.setState({ value: newValue });
    if (newValue?.value === 'other') {
      this.setState({ myActiveElementType: 'input' });
    }
  }

  handleTextChange(e) {
    let newValue = {
      value: e.target.value,
      label: this.state.value.label,
    };
    this.props.onChange(this.formatValueForAPI(newValue));
    this.setState({ value: newValue });
  }

  cancelTextInput() {
    this.props.onChange(this.formatValueForAPI(this.props.options[0]));
    this.setState({ value: this.props.options[0] });
    this.setState({ myActiveElementType: 'dropdown' });
  }

  dropdownComponent() {
    return (
      <Select
        isClearable={true}
        isMulti={this.props.multi}
        name={this.props.name}
        value={this.state.value}
        components={{ Option: CustomOptions }}
        inputProps={this.props.autocomplete ? { autoComplete: 'on' } : { autoComplete: 'none' }}
        options={this.props.options}
        onChange={this.updateValue.bind(this)}
        onKeyDown={this.handleKeyPress.bind(this)}
        className={this.props.className}
        searchable={isMobile ? false : true}
        style={this.props.size ? { maxWidth: `${this.props.size}px` } : {}}
        classNamePrefix={'customSelect'}
        closeMenuOnSelect={this.props.multi ? false : true}
      />
    );
  }

  inputComponent() {
    return (
      <div className="inline-input-group">
        <input
          type="text"
          key={'input' + this.props.name}
          name={this.props.name}
          id={this.props.name}
          size={this.props.size}
          maxLength={this.props.max}
          className="default-input"
          defaultValue={this.props.value}
          autoComplete={this.props.autocomplete ? 'on' : 'off'}
          onChange={this.handleTextChange.bind(this)}
          style={{ display: 'inline-block' }}
        />
        <span onClick={this.cancelTextInput.bind(this)} style={{ margin: '0 10px 0 -20px' }}>
          &nbsp;x&nbsp;
        </span>
      </div>
    );
  }

  render() {
    return (
      <div>
        <abbr title={this.props.hovertext}>
          {!this.props.multi && this.state.myActiveElementType === 'input'
            ? this.inputComponent()
            : this.dropdownComponent()}
        </abbr>
      </div>
    );
  }
}

WrappedSelect.defaultProps = {
  activeElementType: 'dropdown',
};
