const date = new Date();
const year = date.getFullYear();
const month = date.getMonth();
const firstOfMonth = new Date(year, month, 1);
const daysInMonth = new Date(year, month + 1, 0).getDate();

const initialState = {
  auth: {
    loggingIn: false,
    loggingOut: false,
    loggedIn: false,
    polledNotifications: false,
    inAppNotifications: false,
    userType: null,
    APIKey: null,
    details: null,
  },
  config: {
    apikey: null,
  },
  data: {
    notifications: {
      loaded: false,
      loading: false,
      error: null,
      data: null,
    },
    dashboard: {
      loaded: false,
      loading: true,
      error: null,
      data: null,
    },
    calendar: {
      loaded: false,
      loading: true,
      error: null,
      dayData: null,
      seldate: date.getTime(),
      scope: 'month',
      config: {
        display: {
          startDay: 0,
          weekNumbers: false,
          minDate: null,
          disablePast: false,
          dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          monthNamesFull: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          firstOfMonth: firstOfMonth,
          daysInMonth: daysInMonth,
        },
        data: null,
      },
    },
    callouts: {
      loaded: false,
      loading: false,
      error: null,
      data: null,
    },
    exchangeRequests: {
      loaded: false,
      loading: false,
      error: null,
      data: null,
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_DASHBOARD':
      return {
        ...state,
        loading: true,
      };

    case 'LOAD_DASHBOARD_REJECTED':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'LOAD_DASHBOARD_FULFILLED':
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          dashboard: action.payload,
        },
      };

    case 'LOAD_CALENDAR':
      return {
        ...state,
        loading: true,
      };

    case 'LOAD_CALENDAR_REJECTED':
      console.log('LOAD_CALENDAR_REJECTED');
      console.log(action.payload);
      return { ...state, loading: false, error: action.payload };

    case 'LOAD_CALENDAR_FULFILLED':
      const newDate = Object.keys(action.payload.data)[0].split('-'); //warning super hacky
      const month = +newDate[1] - 1;
      const year = +newDate[0];
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          calendarz: {
            ...state.data.calendar,
            dayData: {
              ...state.data.calendar.dayData,
              ...action.payload.data,
            },
            config: {
              ...state.data.calendar.config,
              month: month,
              year: year,
              scope: 'month',
              firstOfMonth: new Date(year, month, 1),
              daysInMonth: new Date(year, month + 1, 0).getDate(),
            },
            loaded: true,
            loading: false,
          },
        },
      };

    case 'LOAD_DAY_FULFILLED':
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          calendar: {
            ...state.data.calendar,
            dayData: {
              ...state.data.calendar.dayData,
              ...action.payload.data,
            },
            config: {
              ...state.data.calendar.config,
            },
            loaded: true,
            loading: false,
          },
        },
      };

    case 'LOAD_CALLOUTS_FULFILLED':
      return {
        ...state,
        data: {
          ...state.data,
          callouts: {
            loading: false,
            loaded: true,
            error: null,
            data: {
              ...action.payload.data,
            },
          },
        },
      };

    case 'LOAD_CALLOUTS_REJECTED':
      return {
        ...state,
        data: {
          ...state.data,
          callouts: {
            loading: false,
            loaded: true,
            error: {
              ...action.payload.data,
            },
            data: null,
          },
        },
      };

    case 'LOAD_NOTIFICATIONS_FULFILLED':
      return {
        ...state,
        auth: {
          ...state.auth,
          inAppNotifications: true,
        },
        data: {
          ...state.data,
          notifications: {
            loading: false,
            loaded: true,
            error: null,
            data: {
              ...action.payload,
            },
          },
        },
      };

    case 'LOAD_NOTIFICATIONS_REJECTED':
      return {
        ...state,
        data: {
          ...state.data,
          notifications: {
            loading: false,
            loaded: true,
            error: {
              ...action.payload.data,
            },
            data: null,
          },
        },
      };

    case 'LOAD_EXCHANGES_FULFILLED':
      return {
        ...state,
        data: {
          ...state.data,
          exchangeRequests: {
            loading: false,
            loaded: true,
            error: null,
            data: {
              ...action.payload.data,
            },
          },
        },
      };

    case 'LOAD_EXCHANGES_REJECTED':
      return {
        ...state,
        data: {
          ...state.data,
          exchangeRequests: {
            loading: false,
            loaded: true,
            error: {
              ...action.payload.data,
            },
            data: null,
          },
        },
      };

    case 'LOGIN_FULFILLED':
      return {
        ...state,
        auth: {
          loggingIn: false,
          loggingOut: false,
          loggedIn: true,
          polledNotifications: action.payload.polled_notifications,
          inAppNotifications: action.payload.in_app_notifications,
          userType: action.payload.user_type || state.auth.userType,
          APIKey: action.payload.apikey,
          details: {
            ...action.payload,
          },
        },
        notifications: {
          loading: false,
          loaded: true,
          error: {
            ...action.payload.data,
          },
          data: null,
        },
      };

    case 'LOGIN_REJECTED':
      return {
        ...state,
        auth: {
          loggingIn: false,
          loggingOut: false,
          polledNotifications: false,
          inAppNotifications: false,
          loggedIn: false,
          userType: null,
          APIKey: null,
          details: null,
        },
      };

    case 'LOGOUT_FULFILLED':
      return {
        ...initialState,
        auth: {
          loggingIn: false,
          loggingOut: false,
          loggedIn: false,
          polledNotifications: false,
          inAppNotifications: false,
          userType: null,
          APIKey: null,
          details: null,
        },
      };

    case 'CHANGE_PASSWORD_FULFILLED':
      return {
        ...state,
        auth: {
          ...state.auth,
          details: {
            ...state.auth.details,
            force_pw_change: false,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
