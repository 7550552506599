import React from 'react';
import ObjectTree from './Tree';
import OnDeck from './OnDeck';
import Navigation from './Navigation';
import WorkingArea from './WorkingArea';
import APIResponseDialog from '../APIResponseDialog';
import './ObjectEditor.scss';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

export default class OEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      objectData: null,
      treeOpenNodes: [],
      simple: null,
      openNode: 0,
      isScrolled: false,
      idProperty: null,
      APIConfig: {},
      temp_navigation: false,
      selectedTab: null,
      onDeckShrunk: false,
      treeShrunk: false,
      tree: {
        selectedNodes: [],
      },
      onDeck: {
        currentlyViewingIndex: null,
        currentlyViewing: null,
        selectedNodes: [],
      },
      workingArea: {
        menuButtonSelected: 0, //default to the first one
        menuObject: [],
      },
      style: {
        tree: {
          label: '',
          class: '',
          icon: '',
          iconClass: '',
        },
        working_area: {
          label: '',
          class: '',
          icon: '',
          iconClass: '',
        },
        ondeck: {
          label: '',
          class: '',
          icon: '',
          iconClass: '',
        },
      },
      dialog_box: {
        data: null,
        show: false,
      },
      dialog: {
        open: false,
        response: null,
      },
    };

    this.updateSelectedTab = this.updateSelectedTab.bind(this);
    this.toggleTreeExpand = this.toggleTreeExpand.bind(this);
    this.toggleOnDeckExpand = this.toggleOnDeckExpand.bind(this);
    this.configureAPI = this.configureAPI.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.clearOnDeck = this.clearOnDeck.bind(this);
    this.moveToOnDeck = this.moveToOnDeck.bind(this);
    this.removeNodeFromOnDeck = this.removeNodeFromOnDeck.bind(this);
    this.parseData = this.parseData.bind(this);
    this.addNodeToSelectionTree = this.addNodeToSelectionTree.bind(this);
    this.removeNodeFromSelectionTree = this.removeNodeFromSelectionTree.bind(this);
    this.selectOnDeckNode = this.selectOnDeckNode.bind(this);
    this.selectSimpleObject = this.selectSimpleObject.bind(this);
  }

  componentDidMount() {
    if (this.props.objectData && this.props.objectData.config) {
      this.parseData(this.props.objectData);
    } else {
      console.warn('check JSON, might be missing a config property');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.objectData && this.props.objectData.config) {
      this.parseData(this.props.objectData);
    } else if (!this.props.objectData || !this.props.objectData.config) {
      console.warn('check JSON, might be missing a config property');
    }
  }

  parseData(objectData) {
    let tree = {
      label: '',
      class: '',
      icon: '',
      iconClass: '',
    };
    let ondeck = {
      label: '',
      class: '',
      icon: '',
      iconClass: '',
    };
    let working_area = {
      label: '',
      class: '',
      icon: '',
      iconClass: '',
    };

    if (objectData.config.style) {
      if (objectData.config.style.tree) {
        tree['label'] = objectData.config.style.tree.label;
        tree['class'] = objectData.config.style.tree.class;
        tree['icon'] = objectData.config.style.tree.icon;
        tree['iconClass'] = objectData.config.style.tree.iconClass;
      }
      if (objectData.config.style.working_area) {
        working_area['label'] = objectData.config.style.working_area.label;
        working_area['class'] = objectData.config.style.working_area.class;
        working_area['icon'] = objectData.config.style.working_area.icon;
        working_area['iconClass'] = objectData.config.style.working_area.iconClass;
      }
      if (objectData.config.style.ondeck) {
        ondeck['label'] = objectData.config.style.ondeck.label;
        ondeck['class'] = objectData.config.style.ondeck.class;
        ondeck['icon'] = objectData.config.style.ondeck.icon;
        ondeck['iconClass'] = objectData.config.style.ondeck.iconClass;
      }
    }

    //Default open node to 0 in case we don't find a match
    let openNode = 0;
    if (objectData.config.open_node && objectData.config.selected_nodes) {
      objectData.config.selected_nodes.map((node, index) => {
        if (node.id === objectData.config.open_node) {
          openNode = index;
        }
        return node;
      });
    }

    //These nodes will be opened at the start
    //TODO: I doubt we'll need this
    const treeOpenNodes = [];
    if (objectData && objectData.config && objectData.config.tree) {
      for (let tree of objectData.config.tree) {
        if (tree.open) {
          treeOpenNodes.push(tree.id);
        }
        if (Array.isArray(tree.objects)) {
          for (let object of tree.objects) {
            if (object.open) {
              treeOpenNodes.push(object.id);
            }
          }
        }
      }
    }
    // Remove from the OnDeck selectedNodes, any that are no longer
    // in the tree after this update. This makes refresh after delete a lot cleaner.
    //
    let newNodes=[];
    const ids = [];// Used to only add node once
    if (Array.isArray(this.state.onDeck.selectedNodes) && this.state.onDeck.selectedNodes.length &&  
       objectData && objectData.config && objectData.config.tree) {
      for (let tree of objectData.config.tree) {
        if (tree && tree.objects && tree.objects.length > 0) {
          for (let n of this.state.onDeck.selectedNodes) {
            for (let object of tree.objects) {
              // The tree can nest two more levels.
              // I think that is the limit. 
              // TODO. Consider a recursive search.
              if (Array.isArray(object.objects)) {
                for (let object2 of object.objects) {
                  if (Array.isArray(object2.objects)) {
                    for (let object3 of object2.objects) {
                      if ( n.id == object3.id && !ids.includes(n.id)) {
                        ids.push(n.id);
                        newNodes.push(object3);
                      }
                    }
                  } else if ( n.id == object2.id && !ids.includes(n.id)) {
                    ids.push(n.id);
                    newNodes.push(object2);
                  }
                }
              } else if ( n.id == object.id && !ids.includes(n.id)) {
                ids.push(n.id);
                newNodes.push(object);
              }
            }
          }
        }
      } 
    } else if (Array.isArray(this.state.onDeck.selectedNodes) && this.state.onDeck.selectedNodes.length &&  
       objectData && objectData.config && objectData.config.objects) {
      for (let n of this.state.onDeck.selectedNodes) {
        for (let object of objectData.config.objects) {
          // The tree can nest two more levels.
          // I think that is the limit. 
          // TODO. Consider a recursive search.
          if (Array.isArray(object.objects)) {
            for (let object2 of object.objects) {
              if (Array.isArray(object2.objects)) {
                for (let object3 of object2.objects) {
                  if ( n.id == object3.id && !ids.includes(n.id)) {
                    ids.push(n.id);
                    newNodes.push(object3);
                  }
                }
              } else if ( n.id == object2.id && !ids.includes(n.id)) {
                ids.push(n.id);
                newNodes.push(object2);
              }
            }
          } else if ( n.id == object.id && !ids.includes(n.id)) {
            ids.push(n.id);
            newNodes.push(object);
          }
        }
      }
    } else {
      newNodes = this.state.tree.selectedNodes;
    }

    this.setState(
      prevState => ({
        objectData,
        idProperty: objectData.config.action.id,
        simple: this.props.simple,
        treeOpenNodes,
        openNode,
        tree: {
          selectedNodes: newNodes,
        },
        onDeck: {
          currentlyViewingIndex: prevState.onDeck.currentlyViewingIndex || 0,
          currentlyViewing: prevState.onDeck.currentlyViewing || newNodes[0],
          selectedNodes: newNodes,
        },
        loading: false,
        dialog_box: {
          data: null,
          show: false,
        },
        style: {
          tree,
          ondeck,
          working_area,
        },
      })
    );
  }

  updateSelectedTab(selectedTab) {
    this.setState({
      selectedTab,
    });
  }

  openDialog(response, buttonCallback, button = null) {
    // just bump it open then immediately close on this end, to leave timing
    // up to the dialog component
    this.setState(
      {
        loading: false,
        dialog: {
          open: true,
          response,
          buttonCallback,
        },
      },
      () => {
        setTimeout(() => {
          this?.setState({ dialog: { open: false, response, buttonCallback, button } });
        }, 3000);
      },
    );
  }

  addNodeToSelectionTree(nodes, pushToOnDeck) {
    this.setState(
      prevState => ({
        tree: {
          ...prevState.tree,
          selectedNodes: prevState.tree.selectedNodes.concat(nodes),
        },
      }),
      () => pushToOnDeck && this.moveToOnDeck(),
    );
  }

  removeNodeFromSelectionTree(nodes) {
    this.setState(prevState => ({
      tree: {
        ...prevState.tree,
        selectedNodes: prevState.tree.selectedNodes.filter(node => !nodes.includes(node)),
      },
    }));
  }

  moveToOnDeck() {
    const selectedNodes = this.state.tree.selectedNodes.reduce((unique, o) => {
      if (!o.expandable && !unique.some(obj => obj.label === o.label && obj.id === o.id)) {
        unique.push(o);
      }

      return unique;
    }, []);

    if (Array.isArray(selectedNodes) && selectedNodes.length) {
      this.setState(
        prevState => ({
          APIConfig: this.configureAPI(selectedNodes[0]),
          onDeck: {
            currentlyViewingIndex: prevState.onDeck.currentlyViewingIndex || 0,
            currentlyViewing: prevState.onDeck.currentlyViewing || selectedNodes[0],
            selectedNodes,
          },
        }),
        () => isMobile && this.toggleTreeExpand(),
      );
    }
  }

  removeNodeFromOnDeck(node) {
    this.setState(prevState => ({
      onDeck: {
        currentlyViewingIndex:
          prevState.onDeck.currentlyViewing.id === node.id ? 0 : prevState.onDeck.currentlyViewingIndex,
        currentlyViewing:
          prevState.onDeck.currentlyViewing.id === node.id
            ? prevState.onDeck.selectedNodes.filter(({ id }) => node.id !== id)[0]
            : prevState.onDeck.currentlyViewing,
        selectedNodes: prevState.onDeck.selectedNodes.filter(({ id }) => node.id !== id),
      },
    }));
  }

  configureAPI(node) {
    let APIConfig = {};

    if (node.endpoint) {
      //If the object has it's own endpoint then use it
      const { endpoint, endpoint_type, method, variables = {} } = node;
      APIConfig = {
        endpoint,
        endpoint_type,
        method,
        variables,
      };
    } else {
      //If not then use the Endpoint passed on the configuration
      const { endpoint, endpoint_type, method, variables = {} } = this.state.objectData.config.action;
      APIConfig = {
        endpoint,
        endpoint_type,
        method,
        variables: Object.assign({}, variables, {
          [this.state.idProperty]: node[this.state.idProperty],
        }),
      };
    }

    return APIConfig;
  }

  selectOnDeckNode(node, temp_navigation = false) {
    this.setState(
      prevState => ({
        temp_navigation,
        APIConfig: this.configureAPI(node),
        onDeck: {
          ...prevState.onDeck,
          currentlyViewingIndex: prevState.onDeck.selectedNodes.indexOf(node),
          currentlyViewing: node,
        },
      }),
      () => isMobile && !this.state.onDeckShrunk && this.setState({ onDeckShrunk: true }),
    );
  }

  clearOnDeck() {
    this.setState({
      tree: {
        selectedNodes: [],
      },
      onDeck: {
        currentlyViewingIndex: null,
        currentlyViewing: null,
        selectedNodes: [],
      },
    });
  }

  // conditionally enable scroll css styling
  onScroll(event) {
    let scrollTop = event.nativeEvent.srcElement.scrollTop;
    let isScrolled = scrollTop > 8;

    if (isScrolled !== this.state.isScrolled) {
      this.setState({
        isScrolled,
      });
    }
  }

  toggleTreeExpand() {
    this.setState(prevState => ({
      treeShrunk: !prevState.treeShrunk,
    }));
  }

  toggleOnDeckExpand() {
    this.setState(prevState => ({
      onDeckShrunk: !prevState.onDeckShrunk,
    }));
  }

  selectSimpleObject(node) {
    this.setState({
      APIConfig: this.configureAPI(node),
      tree: {
        selectedNodes: [node],
      },
      onDeck: {
        currentlyViewingIndex: 0,
        currentlyViewing: node,
        selectedNodes: [node],
      },
    });
  }

  render() {
    const { objectData, onDeck } = this.state;
    if (!objectData || !objectData.config) {
      return null;
    }

    const { selectedNodes, currentlyViewing, currentlyViewingIndex } = onDeck;
    const tree = objectData.config.tree ? objectData.config.tree : objectData.config.objects;
    let nextObject, prevObject;

    if (selectedNodes.length) {
      nextObject =
        selectedNodes.length === currentlyViewingIndex + 1
          ? selectedNodes[0]
          : selectedNodes[currentlyViewingIndex + 1];
      prevObject =
        currentlyViewingIndex === 0
          ? selectedNodes[selectedNodes.length - 1]
          : selectedNodes[currentlyViewingIndex - 1];
    }

    const onDeckClass = !this.state.isScrolled ? 'unscrolled ' : '';
    const treeShrunkClass = this.state.treeShrunk ? ' treeShrunk ' : '';
    const onDeckShrunkClass = this.state.onDeckShrunk ? ' onDeckShrunk' : '';
    const simpleClass = this.props.simple ? ' simple' : '';

    return (
      <div className={classNames('OEv2', simpleClass, treeShrunkClass, onDeckShrunkClass)}>
        <APIResponseDialog
          buttonCallback={this.state.dialog.buttonCallback}
          button={this.state.dialog.button}
          open={this.state.dialog.open}
          response={this.state.dialog.response}
          notify={true}
        />
        <div className="objectTreeContainer">
          <div className="topPanel">
            {this.state.treeShrunk ? (
              <span className="glyphicon glyphicon-resize-full" onClick={this.toggleTreeExpand} />
            ) : (
              <span className="glyphicon glyphicon-resize-small" onClick={this.toggleTreeExpand} />
            )}
          </div>
          <div className="objectTree">
            <ObjectTree
              tree={tree}
              config={objectData.config}
              simple={this.props.simple}
              selectSimpleObject={this.selectSimpleObject}
              selectedNodes={this.state.tree.selectedNodes}
              openDialog={this.openDialog}
              moveToOnDeck={this.moveToOnDeck}
              refreshObjects={this.props.refreshObjects}
              addNodeToSelectionTree={this.addNodeToSelectionTree}
              removeNodeFromSelectionTree={this.removeNodeFromSelectionTree}
            />
          </div>
        </div>
        <div className="rightSide">
          {!this.props.simple && (
            <div className="topPanel">
              {this.state.onDeckShrunk ? (
                <span className="glyphicon glyphicon-resize-full" onClick={this.toggleOnDeckExpand} />
              ) : (
                <span className="glyphicon glyphicon-resize-small" onClick={this.toggleOnDeckExpand} />
              )}
            </div>
          )}
          {!this.props.simple && (
            <div className={classNames('onDeck', onDeckClass)} onScroll={this.onScroll}>
              <OnDeck
                onClick={this.state.onDeckShrunk && this.toggleOnDeckExpand}
                nodes={selectedNodes}
                config={objectData.config}
                onDeckShrunk={this.state.onDeckShrunk}
                currentlyViewing={currentlyViewing}
                openDialog={this.openDialog}
                clearOnDeck={this.clearOnDeck}
                refreshObjects={this.props.refreshObjects}
                selectOnDeckNode={this.selectOnDeckNode}
                removeNodeFromOnDeck={this.removeNodeFromOnDeck}
              />
            </div>
          )}
          {!this.props.simple && (
            <div className="navigation">
              <Navigation
                nextObject={nextObject}
                prevObject={prevObject}
                selectOnDeckNode={this.selectOnDeckNode}
                currentlyViewing={currentlyViewing}
              />
            </div>
          )}
          {this.props.simple && <div className="topPanel" />}
          <div className="workingArea">
            {currentlyViewing && this.state.APIConfig.endpoint && (
              <WorkingArea
                key={currentlyViewing ? 'workingArea ' + currentlyViewing.id : 'workingArea'}
                idProperty={this.state.idProperty}
                temp_navigation={this.state.temp_navigation}
                selectedTab={this.state.selectedTab}
                genericAPIConfig={objectData.config.action}
                APIConfig={this.state.APIConfig}
                currentlyViewing={currentlyViewing}
                onClick={!this.state.onDeckShrunk && this.toggleOnDeckExpand}
                openDialog={this.openDialog}
                refreshObjects={this.props.refreshObjects}
                updateSelectedTab={this.updateSelectedTab}
                history={this.props.history}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
