import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { cookieAuth } from '../actions/GlobalActions';

import HeaderPage from '../containers/HeaderPage';
import Footer from '../components/AppLayout/Footer';
import '../styles/bootstrap.css';
import { Row, Col, Image } from 'react-bootstrap';

// const userTypes = require('../constants/userTypes');
// const theme = {
//   DAILYROSTER: 'DAILYROSTER',
// };

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chooseVersion: false,
      checkingCookie: true,
    };
  }

  getTabs() {
    return this.props.headerTabs || [];
  }

  getNotifications() {
    return this.props.notifications || [];
  }

  componentDidMount() {
    const cookies = new Cookies();
    if (cookies.get('ndAPIKey') && cookies.get('ndUserType')) {
      this.props
        .cookieAuth(cookies.get('ndAPIKey'), cookies.get('ndUserType'))
        .then(pl => this.setState({ checkingCookie: false }));
    } else {
      this.setState({
        checkingCookie: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const isLoggingOut = prevProps.isLoggedIn && !this.props.isLoggedIn;
    const isLoggingIn = !prevProps.isLoggedIn && this.props.isLoggedIn;

    if (isLoggingIn) {
      const { details } = this.props;
      // console.log("details", details);
      if (details.version === 4 && details.url) {
        const cookies = new Cookies();
        cookies.set('domain', details.url);
        window.location.href = this.validateUrl(details.url);
      } else if (details.version === 'choice') {
        this.setState({ chooseVersion: true });
      } else {
        //this.props.history.push('/user/dashboard');
      }
    } else if (isLoggingOut) {
      // do any kind of cleanup or post-logout redirection here
      const cookies = new Cookies();
      cookies.remove('ndAPIKey', { path: '/' });
      cookies.remove('ndUserType', { path: '/' });
      this.props.history.push('/login' + window.location.search);
    }
  }

  validateUrl(url) {
    if (url.indexOf('http') !== -1) {
      return url;
    } else {
      return `http://${url}`;
    }
  }

  handleVersionSelected(choice) {
    if (choice.version_no === 4) {
      /* Tell Allen to include "http://" or "https://" accordingly in the URL
       ** and to include a token in the URL. */
      window.location.href = this.validateUrl(choice.version_url);
    } else {
      this.setState({ chooseVersion: false });
    }
  }

  getVersionDialog() {
    const choices = Object.keys(this.props.details.url).map(vn => {
      let label = 'Version ' + vn;
      if (this.props.details.label) {
        label = this.props.details.label[vn] || 'Version ' + vn;
      }
      return {
        version_no: vn,
        version_url: this.props.details.url[vn],
        label,
      };
    });
    return (
      <div id="version-wrapper">
        <div className="version-selection-wrapper">
          <span className="selection-title">You have both versions available</span>
          <span className="selection-sub-text">Select one to complete the login</span>
        </div>
        {choices.map(choice => (
          <div key={choice.version_no} className="version-choice" onClick={() => this.handleVersionSelected(choice)}>
            <h2>{choice.label}</h2>
          </div>
        ))}
      </div>
    );
  }

  render() {
    if (this.state.chooseVersion && this.props.details) {
      return (
        <div>
          <div id="topContainer">
            <div className="innerContainer">
              <div className="container-fluid" style={{ padding: '0' }}>
                <div id="headerRow" className="nd-row">
                  <Col xs={6}>
                    <Image
                      src={require('../img/'+process.env.REACT_APP_HEADER_LOGO).default}
                      id="headerLogo"
                      style={{ float: 'left', paddingBottom: '3px' }}
                    />
                  </Col>
                </div>
              </div>
            </div>
          </div>
          {this.getVersionDialog()}
          <div style={{ position: 'fixed', left: '0', bottom: '5px', width: '100%' }}>
            <Row>
              <Footer />
            </Row>
          </div>
        </div>
      );
    }
    if (this.state) {
      // let themeClass = "";
      // if(!this.props.isLoggedIn && this.props.style && this.props.style.config && this.props.style.config.theme){
      //   if(this.props.style.config.theme.toUpperCase() === theme.DAILYROSTER){
      //     themeClass = theme.DAILYROSTER;
      //   }
      // }
      // const login_background = {
      //   backgroundImage: `url(${require('../img/calendar_mouse.jpg').default})`,
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      // };
      let displayData;
      if (this.props.style) {
        displayData = this.props.style.config ? this.props.style.config.header : null;
      }
      return (
        <div>
          <HeaderPage
            currentPage={this.props.location.pathname}
            displayData={displayData}
            tabData={this.getTabs()}
            notificationsData={this.getNotifications()}
            checkingCookie={this.state.checkingCookie}
          />

          { !this.props.isLoggedIn && (
            <Row>
              <Footer />
            </Row>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state, ownProps) {
  // console.log('mappin state to props in appcontainer');
  // console.log(state);
  // console.log(ownProps);
  return {
    details: state.APIReducer.auth.details,
    isLoggedIn: state.APIReducer.auth.loggedIn,
    polledNotifications: state.APIReducer.auth.polledNotifications,
    inAppNotifications: state.APIReducer.auth.inAppNotifications,
    style: state.ConfigReducer.userPage,
    headerTabs: state.ConfigReducer.headerTabs,
    userType: state.APIReducer.auth.userType,
    notifications: state.APIReducer.data.notifications,
  };
}
function mapDispatchToProps(dispatch) {
  // console.log('mappin dispatch to props');
  // console.log(dispatch);
  return {
    cookieAuth: bindActionCreators(cookieAuth, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppContainer));
