import React from 'react';
import Form from '../../Common/Form';
import CustomToolTip from '../../Common/CustomToolTip';
import classNames from 'classnames';

export default class CommandPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      selectedButton: null,
      results: null,
      objects: null,
      showForm: true,
      style: {
        leftmenu: {
          label: '',
          class: '',
          icon: '',
          iconClass: '',
        },
        commandpanel: {
          label: '',
          class: '',
          icon: '',
          iconClass: '',
        },
        results: {
          label: '',
          class: '',
          icon: '',
          iconClass: '',
        },
      },
    };
  }

  parseData(data) {
    // copy over the relevant stuff so that if
    // format changes this is the only place we have to change it

    //parse the buttons
    let leftmenu = data.leftmenu || { buttons: [] };
    let newButtons = [];
    if (leftmenu && leftmenu.buttons) {
      for (let i = 0; i < leftmenu.buttons.length; i++) {
        let button = leftmenu.buttons[i];
        let newButton = {
          label: button.label,
          form: button.form,
        };
        newButtons.push(newButton);
      }
      leftmenu.buttons = newButtons;
    }

    let newData = {
      leftmenu: leftmenu,
    };

    //Handle the Styling of the Headers
    let leftmenu_style = {
      label: '',
      class: '',
      icon: '',
      iconClass: '',
    };
    let commandpanel = {
      label: '',
      class: '',
      icon: '',
      iconClass: '',
    };
    let results = {
      label: '',
      class: '',
      icon: '',
      iconClass: '',
    };
    if (data.style) {
      if (data.style.leftmenu) {
        leftmenu_style['label'] = data.style.leftmenu.label;
        leftmenu_style['class'] = data.style.leftmenu.class;
        leftmenu_style['icon'] = data.style.leftmenu.icon;
        leftmenu_style['iconClass'] = data.style.leftmenu.iconClass;
      }
      if (data.style.commandpanel) {
        commandpanel['label'] = data.style.commandpanel.label;
        commandpanel['class'] = data.style.commandpanel.class;
        commandpanel['icon'] = data.style.commandpanel.icon;
        commandpanel['iconClass'] = data.style.commandpanel.iconClass;
      }
      if (data.style.results) {
        results['label'] = data.style.results.label;
        results['class'] = data.style.results.class;
        results['icon'] = data.style.results.icon;
        results['iconClass'] = data.style.results.iconClass;
      }
    }

    this.setState({
      data: newData,
      style: {
        leftmenu: leftmenu_style,
        commandpanel,
        results,
      },
    });
  }

  componentDidUpdate(prevProps) {
    // This was the fix required to reload the ControlPanel after a save.
    prevProps.data.data !== this.props.data.data && this.parseData(this.props.data.data);
  }

  componentDidMount() {
    this.parseData(this.props.data.data);
  }

  getButtonAction(button) {
    return function () {
      this.setState({
        selectedButton: button,
        results: null,
        showForm: true,
      });
    }.bind(this);
  }

  //used as a success callback to form.
  // TODO: set objects here as well
  setResults(data) {
    let html = null;

    if (data.data.hide_results) {
      return;
    }
    if (typeof data.data?.fullmsg === 'string') {
      html = data.data.fullmsg;
    } else if (typeof data.data === 'string') {
      html = data.data;
    }
    let resultMessage = <div className="resultMessage" dangerouslySetInnerHTML={{ __html: html }} />;
    this.setState({
      results: resultMessage,
      showForm: data.status >= 200 && data.status < 300 ? false : true, //collapse form on successful responses
    });
  }

  //TODO: abstract this from form and OE
  getButtons() {
    //if there's data, get the buttons
    if (this.state.data) {
      let buttons = [];
      let rawButtons = this.state.data.leftmenu.buttons ?? [];
      for (let i = 0; i < rawButtons.length; i++) {
        let button = rawButtons[i];
        let icon = null;
        if (button.icon) {
          icon = <CustomToolTip icon={button.icon} iconClass={button.iconClass} />;
        }
        let extraClass = '';
        if (this.state.selectedButton && this.state.selectedButton.label === button.label) {
          extraClass = 'selected ';
        }
        buttons.push(
          <button
            key={'button' + button.label}
            className={classNames('leftMenuButton nd-button', extraClass, button.class)}
            type="button"
            onClick={this.getButtonAction(button)}
            value={button.label}
          >
            {icon}
            {button.label}
          </button>,
        );
      }
      return <div className="buttonContainer">{buttons}</div>;
    } else {
      return null;
    }
  }

  //return the form component based on the selected button
  getForm() {
    if (this.state.selectedButton) {
      return (
        <Form
          formData={this.state.selectedButton.form}
          key={'form' + this.state.selectedButton.label}
          standAlone={false}
          success={this.setResults.bind(this)}
          onSubmit={this.props.refreshData}
        />
      );
    } else {
      return null;
    }
  }

  toggleShowForm() {
    this.setState({
      showForm: !this.state.showForm,
    });
  }

  render() {
    //determine whether or not to show object panel and adjust accordingly
    let showObjectsClass = this.state.objects ? ' showObjects' : '';
    let showFormClass = this.state.showForm ? ' showForm' : '';
    let objectDisplay = this.state.objects ? (
      <div className="middle editorSection contentCard" key="nav">
        <div className="cardBody">{this.state.objects}</div>
      </div>
    ) : null;

    return (
      <div className={classNames('commandPanel', showObjectsClass, showFormClass)}>
        <div className="left">
          <div className="leftPanel editorSection contentCard">
            <div className={'cardHeader ' + this.state.style.leftmenu.class}>
              <div className="header-icon">
                <CustomToolTip icon={this.state.style.leftmenu.icon} iconClass={this.state.style.leftmenu.iconClass} />
              </div>
              <span>{this.state.style.leftmenu.label}</span>
            </div>
            <div className="cardBody">{this.getButtons()}</div>
          </div>
        </div>
        <div className="right">
          <div
            key="top"
            className={classNames(
              'editorSection contentCard',
              { top: !!this.state.results },
              { 'top-no-bottom': !!!this.state.results },
            )}
            onClick={() => !this.state.showForm && this.toggleShowForm()}
          >
            <div className={'cardHeader ' + this.state.style.commandpanel.class}>
              <div className="header-icon">
                <CustomToolTip
                  icon={this.state.style.commandpanel.icon}
                  iconClass={this.state.style.commandpanel.iconClass}
                />
              </div>
              <span>{this.state.style.commandpanel.label}</span>
            </div>
            <div className="cardBody">{this.getForm()}</div>
          </div>
          {objectDisplay}
          {this.state.results && (
            <div
              className={'bottom editorSection contentCard'}
              onClick={() => this.state.showForm && this.toggleShowForm()}
            >
              <div className={'cardHeader ' + this.state.style.results.class}>
                <div className="header-icon">
                  <CustomToolTip icon={this.state.style.results.icon} iconClass={this.state.style.results.iconClass} />
                </div>
                <span>{this.state.style.results.label}</span>
              </div>
              <div className="cardBody">{this.state.results}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
