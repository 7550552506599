import React from 'react';
import CustomToolTip from './CustomToolTip';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { FaPen, FaCircle, FaRegTrashAlt, FaPlus, FaRegClock, FaCalendarCheck } from 'react-icons/fa';
import { BiSun } from 'react-icons/bi';
import { HiEye } from 'react-icons/hi2';
import { BsCalendar3, BsFillCalendar2DayFill, BsCalendarDate } from 'react-icons/bs';

const buttonTypes = require('../../constants/buttonTypes');

export default class Buttonbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (Array.isArray(this.props.buttonbar)) {
      let selected = [];
      let onClickExists = false;
      for (let button of this.props.buttonbar) {
        if (button && button.action === 'toggle' && Array.isArray(button.form_controllers)) {
          for (let control of button.form_controllers) {
            if (Object.keys(control).includes('default')) {
              onClickExists = true;
              if (control.default) {
                selected.push(button.id ? button.id : button.label);
              }
            }
          }
        }
      }

      if (selected.length || onClickExists) {
        this.setState({
          selected,
        });
      }
    }
  }

  getIcon(icon, iconClass, hovertext, type = null) {
    let fonticon;
    const icon_color = process.env.REACT_APP_THEME === "epr" ? "#344054" : "";
    const icon_color2 = process.env.REACT_APP_THEME === "epr" ? "white" : "";

    if (icon === 'edit') {
      fonticon = <FaPen color={icon_color} title={hovertext} />;
    } else if (icon === 'delete') {
      fonticon = <FaRegTrashAlt color={icon_color} title={hovertext} />;
    } else if (icon === 'asterisk') {
      fonticon = <BiSun />;
    } else if (icon === 'view') {
      fonticon = <HiEye color={icon_color} />;
    } else if (icon === 'plus') {
      fonticon = <FaPlus color="silver" title={hovertext} />;
    } else if (icon === 'month') {
      fonticon = <BsCalendar3 title={hovertext} size="36" color={icon_color2} />;
    } else if (icon === 'day') {
      fonticon = <BsFillCalendar2DayFill title={hovertext} size="36" color={icon_color2} />;
    } else if (icon === 'week') {
      fonticon = <BsCalendarDate title={hovertext} size="36" color={icon_color2} />;
    } else if (icon === 'overtime') {
      fonticon = <FaRegClock title={hovertext} size="36" color={icon_color2} />;
    } else if (icon === 'payperiod') {
      fonticon = <FaCalendarCheck title={hovertext} size="36" color={icon_color2} />;
    } else {
      fonticon = (
        <CustomToolTip
          icon={icon}
          iconClass={iconClass}
          text={hovertext}
          extraClass="cursor-pointer"
          largeIcon={type}
        />
      );
    }
    return <>{fonticon}</>;
  }

  getButton(button) {
    const { selected } = this.state;
    const hover_class = process.env.REACT_APP_THEME === "epr" ? "hover-class-epr" : "hover-class-netduty";
    const selected_class = process.env.REACT_APP_THEME === "epr" ? "selected-epr" : "selected-netduty";
    const large_class = process.env.REACT_APP_THEME === "epr" ? "large-style-epr" : "large-style-netduty";

    let isSelected;
    if (Array.isArray(selected)) {
      if (selected.includes(button.id) || selected.includes(button.label)) {
        isSelected = true;
      } else {
        isSelected = false;
      }
    } else {
      if (selected && (selected === button.id || selected === button.label)) {
        isSelected = true;
      } else {
        isSelected = false;
      }
    }

    if (button.type === buttonTypes.OUTPUT && button.formParams && button.inputFields) {
      const { inputFields } = button;
      const { action, method, target } = button.formParams;
      return (
        <abbr title={button.hovertext}>
          <form action={action} target={target} method={method}>
            {inputFields.map(({ name, type, value }) => (
              <input
                key={name}
                className={classNames('nd-button ', button.class)}
                name={name}
                type={type}
                value={value}
              />
            ))}
            <input
              type="submit"
              className={classNames('nd-button ', button.class)}
              value={button.label}
            />
          </form>
        </abbr>
      );
    }
    if (button.type === 'large') {
      return (
        <abbr title={button.hovertext}>
          <div
            className={classNames('nd-button ', hover_class, isSelected ? selected_class : large_class, button.class)}
          >
            <div style={{ textAlign: 'center' }}>
              <div style={{ height: '36px' }}>
                {this.getIcon(button.icon, button.iconClass, button.hovertext, button.type)}
              </div>
              <label
                style={{
                  marginTop: '5px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {button.label}
              </label>
            </div>
          </div>
        </abbr>
      );
    }
    if (button.icon && !button.label) {
      return this.getIcon(button.icon, button.iconClass, button.hovertext);
    } else if (button.label && !button.icon) {
      return (
        <abbr title={button.hovertext}>
          <Button
            size="small"
            className={classNames('nd-button ', button.class)}
            style={
              isSelected
                ? {
                    backgroundColor: 'white',
                    color: '#000',
                    boxShadow: '2px 2px 5px inset #c1c1c1',
                  }
                : {}
            }
          >
            {button.label}
          </Button>
        </abbr>
      );
    } else if (button.label && button.icon) {
      return (
        <abbr title={button.hovertext}>
          <Button
            size="small"
            className={classNames('nd-button ', button.class)}
            style={
              isSelected
                ? {
                    backgroundColor: 'white',
                    color: '#000',
                    paddingLeft: '2px',
                    WebkitBoxShadow: '2px 2px 5px inset #c1c1c1',
                    boxShadow: '2px 2px 5px inset #c1c1c1',
                  }
                : { paddingLeft: '2px' }
            }
          >
            {this.getIcon(button.icon, button.iconClass, button.hovertext)}
            <span style={{ marginLeft: '5px' }}>{button.label}</span>
          </Button>
        </abbr>
      );
    }
    console.warn('button is not valid', button);
    return null;
  }

  //We used this as a hacky way of opening the DialogBox but we have now solved that issue
  //Leave this right here for now, we should remove it in the future once we know we don't
  //need it for sure.
  handleClick(e, button, uniqueID) {
    if (button.action === 'toggle') {
      if (
        this.state.selected &&
        (this.state.selected.includes(button.id) || this.state.selected.includes(button.label))
      ) {
        this.setState(prevState => ({
          selected: prevState.selected.filter(
            selectedBtn => !(selectedBtn === button.id || selectedBtn === button.label),
          ),
        }));
      } else {
        this.setState(prevState => ({
          selected: prevState.selected.concat(button.id ? [button.id] : [button.label]),
        }));
      }
    } else if (this.props.selectorMode) {
      this.setState({
        selected: button.id,
      });
    } else {
      this.state.selected && this.setState({ selected: null });
    }
    if (this.props.onClick) {
      this.props.onClick(button, this.props.groupButton);
      return;
    }
    e.stopPropagation();
    let event, eventDoc, doc, body;

    event = e || window.event; // IE-ism

    // If pageX/Y aren't available and clientX/Y are,
    // (This is to support old IE)
    if (event.pageX === null && event.clientX !== null) {
      eventDoc = (event.target && event.target.ownerDocument) || document;
      doc = eventDoc.documentElement;
      body = eventDoc.body;

      event.pageX =
        event.clientX +
        ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
        ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
      event.pageY =
        event.clientY +
        ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
        ((doc && doc.clientTop) || (body && body.clientTop) || 0);
    }

    this.props.handleButton && this.props.handleButton(button, uniqueID, { x: e.pageX, y: e.pageY });
  }

  render() {
    return (
      <div className={classNames('lbuttonbar nd-inline-block', this.props.ndClass)} style={this.props.style}>
        {this.props.buttonbar &&
          Array.isArray(this.props.buttonbar) &&
          this.props.buttonbar.map((button, index) => {
            let extraClass = '';

            if (!button.always_enabled && this.props.loading) {
              extraClass = ' button-disabled';
            }

            return button.type === buttonTypes.OUTPUT ? (
              <div
                className={'nd-single-button nd-inline-block ' + (this.props.btnClass || '') + extraClass}
                key={button.label + button.icon + button.hovertext + index}
              >
                {this.getButton(button)}
              </div>
            ) : (
              <div
                className={'nd-single-button nd-inline-block ' + (this.props.btnClass || '') + extraClass}
                key={button.label + button.icon + button.hovertext + index}
                onClick={event =>
                  (button.always_enabled || !this.props.loading) &&
                  this.handleClick(event, button, button.icon + index + this.props.uniqueID)
                }
              >
                {/* <div className="button-status"> */}
                {/* </div> */}
                {this.getButton(button)}
              </div>
            );
          })}
      </div>
    );
  }
}
