import React from 'react';
import Records from '../Records/Records';
import { getReport, dynamicOEButtonCall } from '../../actions/API';
import Buttonbar from '../Common/Buttonbar';
import { Chart as ChartJS, Title, PointElement, BarElement, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, Filler } from 'chart.js';
import { Pie, Doughnut, Line, Bubble, Bar, Scatter  } from 'react-chartjs-2';

const buttonTypes = require('../../constants/buttonTypes.js');

export default class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartType: null,
      chartOptions: null,
      dataset: null,
    };
  }
  setData(chart_type, options, data) {
    this.setState({
      chartType: chart_type ? chart_type : 'Pie',
      chartOptions: options ? options : null,
      dataset: data ? data : null,
    });
  }

  //when the component mounts, pull the correct report
  componentDidMount() {
    getReport({ report_id: this.props.report.id }).then(response => {
      if (response.status === 200) {

        let chart_type, options, data, dataset;
        data = response.data.data !== '' ? response.data.data : null;
        chart_type = data.chart_type ? data.chart_type : null;
        options = data.options ? data.options : null;
        dataset = data.dataset ? data.dataset : null;
        this.setData(chart_type, options, dataset);
      } else {
        console.error(`Chart::getReport: Error response from API ${response.code}`);
      }
    });

  }

  getChart() {
    const {chartType, options, dataset} = this.state;
    ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, Title, Legend, Filler);
    switch(chartType) {
      case 'Pie':
        return <Pie data={dataset} />;
      case 'Doughnut':
        return <Doughnut data={dataset} />;
      case 'Bar':
        return <Bar data={dataset} />;
      case 'Line':
        return <Line options={options} data={dataset} />;
      case 'Scatter':
        return <Scatter options={options} data={dataset} />;
      case 'Bubble':
        return <Bubble options={options} data={dataset} />;
      default:
        break;
    }
    return (<></>);
  }

  /* We still need to bring the complete Buttonbar Functionality, including:
   ** - DialogBoxes
   ** - APIResponseDialog
   */

  getMenu(menuData) {
    let renderedButtonbar = null;
    if (menuData.buttons) {
      renderedButtonbar = (
        <Buttonbar
          buttonbar={menuData.buttons}
          handleButton={this.handleButton.bind(this)}
          style={{ position: 'absolute', right: '5px', top: '25px' }}
        />
      );
    }
    return renderedButtonbar;
  }

  //render the raw HTML of the report under the appropriate label
  render() {
    const chart = this.getChart();
    return (
      <div className="chart">
        {this.state.menuData && this.getMenu(this.state.menuData)}
        <div className="lable">{this.props.report.label}</div>

      {this.state.chartOptions && chart}
      </div>
    );
  }
}
